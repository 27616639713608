import React from 'react';
import { Button } from 'primereact/button';
import { router } from '@inertiajs/react';

import Language from '@Components/Language';

export function EditButtonTemplate({
  edit_route,
  label = Language.get('Open'),
}: {
  edit_route: string;
  label?: string;
}) {
  return (
    <Button
      tooltipOptions={{ showDelay: 1000, hideDelay: 300, position: 'bottom' }}
      text
      tooltip={Language.get(label)}
      // label={label}
      onClick={() => router.get(edit_route)}
      icon="pi pi-pencil"
      pt={{
        icon: { className: 'text-600 text-ls' },
      }}
    />
  );
}
